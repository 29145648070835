import React, { Component } from 'react';
import treatment_photo from '../img/treatmentPhoto.png'

class Treatments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 1,
            interval: 5000,
            currentInterval: 2500,
            itemList: [
                {
                    key: 1,
                    title: "Klassieke gezichtsbehandeling",
                    description: "Uw gezicht bepaalt voor het grootste gedeelte uw uitstraling. Laat uw gezicht dan ook goed verzorgen bij Fleur Wellness and Beauty"
                },
                {
                    key: 2,
                    title: "Pedicure",
                    description: "Uw voeten dragen het lichaam dag in dag uit. Met een pedicure krijgen uw voeten de aandacht die ze verdienen."
                },
                {
                    key: 3,
                    title: "Wellness massage",
                    description: "Door middel van verschillende massage technieken kan u heerlijk ontspannen om vervolgens de drukte van alledag weer aan te kunnen."
                }

            ]
        };
        this.handleBulletChange = this.handleBulletChange.bind(this);
    }

    handleBulletChange(key){
        // Change the current viewed treatment
        this.setState({"current": key});
        this.setState({"currentInterval": 2500});
    };

    componentDidMount(){
        // Loop through the treatments, with 5s interval
        this.timer = setInterval(
            () => {

                if(this.state.interval === this.state.currentInterval){
                    let currentKey = this.state.current;
                    let maxKey = this.state.itemList.length;
                    if(currentKey === maxKey){
                        this.setState({"current": 1});
                        this.setState({"currentInterval": 2500});
                    }else{
                        this.setState({"current": currentKey + 1});
                        this.setState({"currentInterval": 2500});
                    }
                }else{
                    let newCurrent = this.state.currentInterval + 2500;
                    this.setState({"currentInterval": newCurrent});
                }

            },
            2500,
        );
    }

    render() {
        const itemView = this.state.itemList.map((item) =>
                <li key={item.key}>
                    {this.state.current === item.key &&
                        <div>
                            <b>{item.title}</b><br/>
                            {item.description}
                        </div>
                    }
                </li>
        );
        const buttonView = this.state.itemList.map( (item) =>
            <li className={this.state.current === item.key ? "active" : "inactive"} key={item.key} onClick={() => {this.handleBulletChange(item.key);}}>
                &nbsp;
            </li>
        );
        return (
            <div className="Treatments">
                <div className="Container">
                    <img src={treatment_photo} className="Photo" alt="Behandelingen" />
                    <div className="Item">
                        <div className="Title">Behandelingen</div>
                        <div className="Content">
                            Fleur Wellness and Beauty biedt u een veelzijdig aanbod in behandelingen.
                            Hieronder vindt u een kleine greep uit de mogelijke behandelingen.
                            <ul className="ItemList">
                                {itemView}
                            </ul>
                            <ul className="BulletList">
                                {buttonView}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Treatments;
