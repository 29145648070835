import React, { Component } from 'react';
import logo from '../img/logo.png'
import '../components/under_construction.css'

class UnderConstruction extends Component {
    render() {
        return (
            <div className="under_construction">
                <div className="modal">
                    <img src={logo} className="logo" alt="logo" />
                    <div className="title">
                        In aanbouw
                    </div>
                    <div className="content">
                        De website is in aanbouw.
                        Kijk op <a href="https://www.facebook.com/Fleurwellnessandbeauty/" rel="noopener noreferrer" target="_blank">de Facebook-pagina</a> en/of kom later terug!
                    </div>
                </div>
            </div>
        );
    }
}

export default UnderConstruction;
