import React, { Component } from 'react';

class Contact extends Component {
    render() {

        return (
            <div className="Contact">
                <div className="Container">
                    <div className="Item">
                        <div className="Title">Contact</div>
                        <div className="Content">
                            Fleur Wellness and Beauty<br/>
                            Dr. Nolensstraat 1<br/>
                            6467 EL Kerkrade<br/>
                            <br/>
                            <a href="tel://0031641799155">0641799155</a><br/>
                            <a href="mailto:fleur@fleurwellness.nl">fleurwellnessandbeauty@gmail.com</a><br/>
                            <a href="https://www.facebook.com/Fleurwellnessandbeauty/" target="_blank" rel="noreferrer noopener">Facebook</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;
