import React, { Component } from 'react';

class Menu extends Component {
    render() {
        return (
            <div className="Menu">
                <ul className="Container">
                    <li id="menuHome"><a href="http://www.fleurwellness.nl">home</a></li>
                    <li id="menuOver"><a href="#contentOver">over</a></li>
                    <li id="menuBehandelingen"><a href="#contentBehandelingen">behandelingen</a></li>
                    <li id="menuProducten"><a href="#contentProducten">producten</a></li>
                    <li id="menuContact"><a href="#contentContact">contact</a></li>
                </ul>
            </div>
        );
    }
}

export default Menu;
