import React, { Component } from 'react';
import Fleur from '../img/Fleur.png'

class About extends Component {
    render() {
        return (
            <div className="About">
                <img src={Fleur} width="216" height="164" alt="Fleur Soomers-van Treeck" className="Photo" />
                    <div className="Item">
                        <div className="Title">Over Fleur Wellness and Beauty</div>
                        <div className="Content">
                            Fleur Wellness and Beauty is een door Fleur Soomers-van Treeck
                            opgerichte massage- en schoonheidssalon in de wijk Terwinselen in Kerkrade.
                            <br/>
                            De salon kenmerkt zich door de ontspannen en huiselijke sfeer gecombineerd met een zeer professionele begeleiding. Door de
                            ruime ervaring van Fleur Wellness and Beauty met verschillende vormen van massage-,
                            pedicure- en schoonheidsbehandelingen zit er altijd iets voor u bij.
                            <br/>
                            Neem contact op om zelf kennis te maken met de ontspanning die Fleur Wellness and Beauty u kan bieden.
                        </div>
                    </div>
            </div>
        );
    }
}

export default About;
