import React, { Component } from 'react';
import product_photo from '../img/productphoto.png'

class Products extends Component {
    render() {

        return (
            <div className="Products">
                <div className="Container">
                    <img src={product_photo} className="Photo" alt="Producten" />
                    <div className="Item">
                        <div className="Title">Producten</div>
                        <div className="Content">
                            Fleur Wellness and Beauty werkt met de productlijn van Lamdors Global System.
                            Deze productlijn is speciaal ontwikkeld om het weefsel te verbeteren en de celvernieuwing te stimuleren.
                            Dit zorgt ervoor dat verandering van de uiterlijke kenmerken tijdens het ouderwordingsproces worden vertraagd en de fijne lijntjes en rimpels worden verminderd.
                            De producten van Lamdors gebruiken actieve bestandsdelen die ervoor zorgen dat ze op een natuurlijke wijze de huid kunnen herstellen.
                            De producten zijn 100% vrij van parfum, conserveringsmiddelen en kleurstoffen.
                            Daarnaast zijn de verpakkingen van de producten van Lamdors milieuvriendelijk.
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Products;
