import React, { Component } from 'react';
import './theme-default.css';
import UnderConstruction from './components/under_construction'
import Header from './components/header'
import Menu from './components/menu'
import About from './components/about'
import Treatments from './components/treatments'
import Products from './components/products'
import Contact from './components/contact'

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "currentView": 'Home',
            "underConstruction": false
        };
    }
  render() {
    return (
      <div className="App">
          {this.state.underConstruction === true &&
          <UnderConstruction/>
          }
          <Header/>
          {/*<Menu/>*/}
          <About/>
          <Treatments/>
          <Products/>
          <Contact/>
      </div>
    );
  }
}

export default App;
