import React, { Component } from 'react';
import logo from '../img/logo.png'
import header_photo from '../img/TopPhoto.png'

class Header extends Component {
    render() {
        return (
                <div className="Header">
                    <div className="Container">
                        <img src={logo} className="Logo" alt="logo" />
                        <img src={header_photo} className="Photo" alt="Welkom!" />
                        <div className="Tips">
                            <b>Tip!</b><br/>
                            Geef je huid de rust die het verdient, kom eens langs voor een heerlijke massage! Neem contact op met Fleur Wellness and Beauty om jouw rustmoment te plannen. <br/><br/>

                            <div className="Social">
                                <a href="https://www.facebook.com/Fleurwellnessandbeauty/" rel="noopener noreferrer" target="_blank">
                                    <span className="Facebook"><i className="fa fa-facebook"> </i> &nbsp; Vind Fleur op Facebook</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default Header;
